import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BringMeBack } from '../components/shared/bring-me-back';
import { CupLevel } from '../components/shared/cup-level';

export const query = graphql`
  query {
    whoWeAreImg: file(relativePath: { eq: "how_to_play_jumbo_scaled.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cup: file(relativePath: { eq: "cup.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    beerpong: file(relativePath: { eq: "how_to_play_peerpong.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    flipcup: file(relativePath: { eq: "how_to_play_flipcup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chandelier: file(relativePath: { eq: "how_to_play_chandelier.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ragecage: file(relativePath: { eq: "how_to_play_ragecage.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const howToPlayPage = (props: any) => {
  const data = useStaticQuery(query);
  return (
    <Layout>
      <SEO
        keywords={[
          `Würzburg`,
          `Trinkspiele`,
          `Bier Pong`,
          `Rage Cage`,
          `Flip Cup`,
          `Bier Würzburg`,
        ]}
        title="How to Play"
      />
      <section className="mt-28">
        <div className="container relative">
          <div className="header  relative z-10">
            <h1 className="z-10">HOW TO PLAY.</h1>
          </div>
          <div className="grid grid-cols-12 gap-1 ">
            <div className="col-span-12 lg:col-span-9 z-10 bg-red bg-opacity-60 border-8 border-red p-4 mt-8">
              Let’s get it on! Ihr kommt in die Bar und seht Leute, wie sie
              nicht nur Beerpong spielen? Perfekt, denn neben dem Klassiker
              empfehlen wir euch Flip Cup, Chandelier und Rage Cage. Probiert es
              aus und thank us later. Hier zeigen wir euch, wie die Spiele
              funktionieren und was ihr dafür benötigt, damit ihr wie Pros
              direkt an unserer Theke bestellen und flexen könnt.
            </div>

            <div className="absolute xl:left-1/2 left-0 right-0 xl:top-0 bottom-0">
              <Img
                fluid={data.whoWeAreImg.childImageSharp.fluid}
                className="object-right filter grayscale z-0"
                alt="SIGMA Cup Download Menu"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44 xl:mt-80">
        <div className="container">
          <div className="header">
            <h2 className="text-red">BEER PONG</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-9 max-w-7xl mx-auto">
            <div className="lg:col-span-6 col-span-1">
              <h3 className="font-calk uppercase">Was wir brauchen</h3>
              <ul className="list-outside list-disc pl-12">
                <li>20 Red Cups</li>
                <li>2 Black Cups (mit Wasser)</li>
                <li>4 Ping Pong Bälle</li>
                <li>2 Teams</li>
                <li>1 Spiel = ca. 2 Pitcher (à 1,5 L)</li>
              </ul>
            </div>
            <div className="lg:col-span-6 col-span-1 lg:justify-self-end">
              <h3 className="font-calk uppercase">wie voll der red cup ist:</h3>
              <CupLevel level={1} text="ca. halb voll = 150 ml "></CupLevel>
            </div>
            <div className="lg:col-span-12 col-span-1">
              <h3 className="font-calk uppercase">Wie der Tisch aussieht</h3>
              <div className="text-center">
                <Img
                  fluid={data.beerpong.childImageSharp.fluid}
                  className="mx-auto max-w-4xl"
                  alt="How to play beer pong"
                />
              </div>
            </div>
            <div className="lg:col-span-12 col-span-1 text-justify">
              <h3 className="font-calk uppercase">Wie es geht:</h3>
              <p className="text-justify">
                Kennt mittlerweile jede:r. Damit es nicht peinlich wird, falls
                du es noch nicht kennst, hier die Regeln: An jeder Seite stehen
                jeweils 10 rote Becher zur Hälfte gefüllt als Pyramide
                aufgestellt und jeweils ein schwarzer, mit Wasser gefüllt. Es
                ist egal, wie viele Mitspieler:innen die Teams haben. Jedes Team
                hat zwei Versuche, den Ping Pong-Ball im Becher der gegnerischen
                Mannschaft zu platzieren. Wichtig: Ellbogen muss hinter der
                Tischkante sein. Nach zwei Würfen ist das andere Team dran. Wenn
                der Ball im Becher landet, muss das Team, in dessen Pyramide
                getroffen wurde, den Becher wegnehmen und trinken. Wer zuerst
                alle Becher getroffen hat, hat gewonnen.
              </p>
            </div>
            <div className="lg:col-span-12 col-span-1">
              <h3 className="font-calk uppercase">Special Rules</h3>
              <ul className="list-outside list-disc px-12">
                <li>
                  Der Ball kann entweder auf direktem Weg im Becher landen oder
                  mit Auftopsen. Allerdings darf das Team nach dem ersten
                  Auftopsen den Ball wegschlagen.{' '}
                </li>
                <li>
                  Sollte der Ball im Becher landen, aber den Inhalt noch nicht
                  berührt haben (rotiert an den Seiten), darf man den Ball
                  versuchen, weg zu pusten.{' '}
                </li>
                <li>
                  Solltest du innerhalb eines Spielzuges einen Becher doppelt
                  treffen, zählen drei Becher. Du kannst auswählen, welche zwei
                  weitere Becher weg sollen.{' '}
                </li>
                <li>
                  Jedes Team darf während des gesamten Spiels einmal die Becher
                  zusammenstellen lassen und auch entscheiden, in welcher Form.{' '}
                </li>
                <li>
                  Sollte der Ball am Becher abprallen und zu dir zurückrollen,
                  hast du einen Trickshot. Zeig uns deinen besten Move (mit
                  geschlossenen Augen, sitzend, rückwärts, im Handstand etc.).{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44">
        <div className="container">
          <div className="header">
            <h2 className="text-red">FLIP CUP</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-9 max-w-7xl mx-auto">
            <div className="lg:col-span-6">
              <h3 className="font-calk uppercase">Was wir brauchen</h3>
              <ul className="list-outside list-disc pl-12">
                <li>1 Red Cup pro Spieler:in</li>
                <li>2 Teams mit der gleichen Anzahl an Spieler:innen</li>
              </ul>
            </div>
            <div className="lg:col-span-6 lg:justify-self-end">
              <h3 className="font-calk uppercase">wie voll der red cup ist:</h3>
              <CupLevel
                level={0}
                text="Bis zur Mitte zwischen erste und zweite Markierung ~ 100ml"
              ></CupLevel>
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie der Tisch aussieht</h3>
              <Img
                fluid={data.flipcup.childImageSharp.fluid}
                className="mx-auto max-w-4xl"
                alt="How to play flip cup"
              />
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie es geht:</h3>
              <p className="text-justify">
                Ihr entscheidet, welche sich gegenüberstehenden Personen (ganz
                links oder ganz rechts) starten. Die zwei stoßen an, berühren
                mit dem Becher den Tisch und trinken den Becher leer. Sobald er
                leer ist, stellst du den Becher an die Tischkannte, sodass du
                ihn flippen kannst. Ziel ist es, dass er verkehrt herum (also
                auf dem Kopf) landet und stehen bleibt. Sobald dein Team Mate es
                geschafft hat, ist der nächste dran. Welches Team zuerst alle
                Becher erfolgreich geflippt hat, hat gewonnen. Dann wird es Zeit
                für eine Revanche, beginnend von der anderen Seite. Da Flip Cup
                unabhängig von der Anzahl gespielt werden kann (muss nur gerade
                sein :D), schaut euch um und schnappt euch Leute, die entweder
                zu Freund:innen oder Feind:innen werden.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44">
        <div className="container">
          <div className="header">
            <h2 className="text-red">CHANDELIER</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-9 max-w-7xl mx-auto">
            <div className="lg:col-span-6">
              <h3 className="font-calk uppercase">Was wir brauchen</h3>
              <ul className="list-outside list-disc  pl-12">
                <li>1 Red Cup pro Spieler:in, plus 2 Red Cups in der Mitte</li>
                <li>1 Black Cup mit Wasser gefüllt</li>            
                <li>1 Spiel mit 6 Leuten = ca. ½ Pitcher</li>
                <li>Ego Mode: Jede:r für sich</li>
              </ul>
            </div>
            <div className="lg:col-span-6 lg:justify-self-end">
              <h3 className="font-calk uppercase ">
                wie voll der red cup ist:
              </h3>
              <CupLevel
                level={0}
                text="Bis zur Mitte zwischen erste und zweite Markierung ~ 100ml"
              ></CupLevel>
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie der Tisch aussieht</h3>
              <Img
                fluid={data.chandelier.childImageSharp.fluid}
                className="mx-auto max-w-4xl"
                alt="How to play chandelier"
              />
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie es geht:</h3>
              <p className="text-justify">
                Wie der Name sagt: Es steht ein Chandelier in der Mitte.
                Übersetzt heißt es: ein auf dem Kopf stehender Red Cup, auf dem
                wieder ein Red Cup steht. Die Spieler:innen stehen um diesen
                Chandelier herum und haben jeweils einen Red Cup mit ca. ⅓
                Inhalt vor sich sowie einen Ping Pong-Ball in der Hand.
              </p>
              <p className="text-justify">
                {' '}
                The oldest starts: Jede:r versucht nacheinander, den Ping
                Pong-Ball zuerst auf den Tisch zu topsen, um anschließend im
                Chandelier zu landen. Wer es zuerst schafft, ist safe. Das
                bedeutet die restlichen Spieler:innen müssen ihren Cup zuerst
                leer trinken und anschließend flippen. Du stellst den leeren
                Becher an die Tischkannte und flippst ihn mit einem Finger so,
                dass er auf dem Kopf landet und stehen bleibt. Wer es als
                letztes schafft, scheidet aus dem laufenden Spiel aus.
                Anschließend beginnt das Spiel wieder von vorne: Ihr versucht
                nacheinander den Ping Pong-Ball nach Topsen in den Chandelier zu
                treffen. Sobald nur noch zwei Spieler:innen im Game sind, geht’s
                darum, wer den Chandelier zuerst trifft - dann flippt ihr beide.
                Wer es zuerst schafft, ist Winner of all times.
              </p>
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Special Rules</h3>
              <ul className="list-outside list-disc px-12">
                <li>
                  Wenn ihr beim Topsen ausversehen in euren eigenen Becher
                  anstelle des Chandeliers trefft, seid ihr sofort raus. No
                  place for Losers.
                </li>
                <li>
                  Wenn ihr beim Topsen ausversehen in den Red Cup eines
                  Mitspielers anstelle des Chandeliers trefft, gibt es ein
                  Duell: Nur ihr trinkt beide euren Becher aus und flippt ihn
                  anschließend. Wer es zuerst schafft, konnte sich nochmal
                  safen.
                </li>
              </ul>
              <p>
                PS. Auf den Sitzbänken findet ihr Reinigungsstationen, um nach
                jeder Runde kurz drüber zu wischen. Stay hygienic!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-44">
        <div className="container">
          <div className="header">
            <h2 className="text-red">RAGE CAGE</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-9 max-w-7xl mx-auto">
            <div className="lg:col-span-6">
              <h3 className="font-calk uppercase">Was wir brauchen</h3>
              <ul className="list-outside list-disc pl-12">
                <li>
                  Viele Red Cups in der Mitte, ca. 2 - 3 Becher pro Spieler:in
                </li>
                <li>2 Ping Pong-Bälle</li>
                <li>1 Spiel mit 15 Bechern = ¾ Pitcher</li>
                <li>Ego Mode: Jede:r für sich</li>
              </ul>
            </div>
            <div className="lg:col-span-6 lg:justify-self-end">
              <h3 className="font-calk uppercase ">
                wie voll der red cup ist:
              </h3>
              <CupLevel
                level={0}
                text="Bis zur Mitte zwischen erste und zweite Markierung ~ 100ml"
              ></CupLevel>
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie der Tisch aussieht</h3>
              <Img
                fluid={data.ragecage.childImageSharp.fluid}
                className="mx-auto max-w-4xl"
                alt="How to play chandelier"
              />
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Wie es geht:</h3>
              <p className="text-justify">
                Be careful! Das Spiel heißt nicht umsonst rage. Wenn du
                Feind:innen im Spiel haben solltest und nicht viel verträgst:
                Stay away (oder wie wir im SIGMA sagen: Throka). Die Becher
                stehen alle in der Mitte, ca. ⅓ gefüllt. Alle Spieler:innen
                stehen außenrum.
              </p>
              <p className="text-justify">
                Die sich diagonal gegenüberstehenden Personen beginnen und
                nehmen einen Cup aus der Mitte, trinken ihn leer. Jetzt
                versuchen sie, den Ping Pong-Ball erst auf den Tisch topsen zu
                lassen, der anschließend im Becher landet. Solltest du es direkt
                beim ersten Mal schaffen, Props! Du darfst als Belohnung den
                Becher inkl. Ping Pong-Ball an eine:n Spieler:in deiner Wahl
                weitergeben.
              </p>
              <p className="text-justify">
                Solltest du es noch nicht ganz raus haben und es erst beim
                zweiten oder fünfzigsten Mal schaffen, musst du den Becher an
                den/die Spieler:in zu deiner linken Seite weitergeben. Gespielt
                wird immer in Uhrzeiger-Richtung. Ziel ist es, dass sich diese
                Becher gegenseitig einholen. Also Tipp: Solltest du den Ping
                Pong-Ball beim ersten Shot im Becher versenken, macht es Sinn,
                den Becher an eine:n Spieler:in zu geben, der/die in der Nähe
                des anderen Bechers steht. Das “Einholen” der Becher
                funktioniert so: Schafft man es, den Ping PongBall zu versenken,
                während der andere Spieler, der es gerade versucht, neben einem
                steht, darf man den Becher reinstellen. Dieser Turm an Bechern
                wandert nun weiter zur nächsten Person.{' '}
              </p>
              <p className="text-justify">
                Die Person, die keinen Becher mehr hat, muss einen aus der Mitte
                nehmen, austrinken und wieder versuchen, den Ping Pong-Ball im
                Red Cup zu versenken. Das Ende des Spiels ist erreicht, sobald
                keine Becher mehr in der Mitte stehen.
              </p>
            </div>
            <div className="lg:col-span-12">
              <h3 className="font-calk uppercase">Special Rules</h3>
              <ul className="list-outside list-disc px-12">
                <li>
                  Solltest du beim Topsen ausversehen in einen der Becher in der
                  Mitte treffen, musst du ihn wegnehmen, austrinken und dann
                  damit weiterspielen.
                </li>
              </ul>
              <p>
                PS. Auf den Sitzbänken findet ihr Reinigungsstationen, um nach
                jeder Runde kurz drüber zu wischen. Stay hygienic!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-48">
        <div className="container">
          <div className="max-w-5xl">
            <p>
              Ihr habt weitere Games, die wir aufnehmen und aktiv promoten
              sollen? Zeigt sie uns und wir machen den SIGMA-Approved Stempel
              drauf.
            </p>
            <br />
            <p>
              Euer Bar SIGMA-Team,
              <br /> Anastasia, Lisa, Margarita & Sophia.
            </p>
          </div>
        </div>
      </section>
      <div className="container mt-16">
        <BringMeBack hash="whatToPlay"></BringMeBack>
      </div>
    </Layout>
  );
};
export default howToPlayPage;
