import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

export interface CupLevelProps {
  text: string;
  level: number;
}

export const CupLevel = (props: CupLevelProps) => {
  const data = useStaticQuery(graphql`
    query {
      cup: file(relativePath: { eq: "cup.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="flex text-base relative">
      <div className="flex-1" style={{ maxWidth: '150px' }}>
        <Img
          fluid={data.cup.childImageSharp.fluid}
          style={{ maxWidth: '100%' }}
          alt="Sigma Cup"
        />
      </div>

      <div className="flex-1">
        <div
          className="absolute"
          style={{ top: `calc(${props.level == 0 ? '80%' : '50%'} - 50px)` }}
        >
          <span
            className="sigma-arrow left"
            style={{
              maxWidth: '80px',
              maxHeight: '80px',
              width: '50px',
              height: '50px',
            }}
          ></span>
          <br />
          <span className="">{props.text}</span>
        </div>
      </div>
    </div>
  );
};
